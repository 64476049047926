import { ListItem } from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  delayedFadeInAnimation,
  fadeInAnimation,
} from "../../constants/animate";
import { AppLoader } from "../AppLoader/AppLoader";
import { Image } from "../Image/Image";
import { useScroll } from "../Util/useScroll";
import { useWindowResize } from "../Util/useWindowResize";

interface GalleryProps {
  selectedCategory: string;
}

interface Resource {
  asset_id: string;
  bytes: number;
  created_at: string;
  folder: string;
  format: string;
  height: number;
  public_id: string;
  resource_type: string;
  secure_url: string;
  type: string;
  url: string;
  version: number;
  width: number;
}

interface CloudinaryResponse {
  next_cursor: string;
  resources: Resource[];
}

export const Gallery: FC<GalleryProps> = ({ selectedCategory }) => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0);
  const sizeInfo = useWindowResize();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    setImageUrls([]);
    const getImagesByCategories = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_BACKEND_SERVER_URL || ""
        );

        setImageUrls(
          data.resources
            .filter(
              (resource: Resource) =>
                resource.folder === `palgollner/${selectedCategory}`
            )
            .map((resource: Resource) => {
              return resource.url;
            })
        );
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    getImagesByCategories();
    setCount(count + 1);
    controls.set("hidden");
  }, [selectedCategory]);

  return isLoading ? (
    <div className="h-screen w-screen flex justify-center items-center bg-white">
      <AppLoader />
    </div>
  ) : (
    <motion.div
      animate={controls}
      ref={ref}
      initial={"hidden"}
      variants={count > 0 ? fadeInAnimation : delayedFadeInAnimation}
      className="flex flex-col w-full px-8 mt-10"
    >
      <p className="text-xs font-thin mb-5 sm:ml-5 lg:ml-9">
        {t(`NAVIGATION.CATEGORIES.${selectedCategory}`)}
      </p>
      <div className="flex flex-col sm:flex-row sm:flex-wrap w-full sm:justify-center">
        {imageUrls.map((url, index) => {
          return <Image key={index} src={url} />;
        })}
      </div>
      <div className={"sm:px-9"}>
        <ListItem divider sx={{ borderColor: "black" }} />
      </div>
    </motion.div>
  );
};
