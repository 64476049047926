import styled from "@emotion/styled";
import { Tabs, Tab } from "@mui/material";
import classNames from "classnames";
import { motion } from "framer-motion";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { textAnimation } from "../../constants/animate";
import { Category } from "../../models/category.model";
import { useScroll } from "../Util/useScroll";

import classes from "./Navigation.module.scss";

interface NavigationProps {
  selected: Category;
  setSelected: Dispatch<SetStateAction<Category>>;
}

interface StyledTabProps {
  label: string;
}

export const Navigation: FC<NavigationProps> = ({ selected, setSelected }) => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0);

  const AntTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
  ))(({ theme }) => ({
    textTransform: "none",
    paddingBottom: 0,
    minWidth: 0,
    fontWeight: 200,
    marginRight: 10,
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["IBM Plex Sans"].join(","),
    "&:hover": {
      color: "#000",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#000",
      fontWeight: 400,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelected(Object.keys(Category)[newValue] as Category);
  };

  return (
    <motion.div
      animate={controls}
      ref={ref}
      initial={"hidden"}
      variants={textAnimation}
      className="flex w-full justify-center px-16 flex-wrap"
    >
      <Tabs
        value={Object.keys(Category).indexOf(selected)}
        onChange={handleChange}
        sx={{
          "& .MuiTabs-indicator": {
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #fff",
            width: "100%",
            backgroundColor: "#000",
            borderLeft: "10px solid #fff",
            borderRight: "10px solid #fff",
          },
        }}
      >
        {Object.keys(Category).map((category) => {
          return (
            <AntTab
              key={category}
              label={t(`NAVIGATION.CATEGORIES.${category}`)}
            />
          );
        })}
      </Tabs>
    </motion.div>
  );
};
