import { HomePage } from "./pages/HomePage";

import "./styles/index.css";

function App() {
  return (
    <div className="App font-ibm">
      <HomePage />
    </div>
  );
}

export default App;
