import classNames from "classnames";
import { motion } from "framer-motion";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { IoLogoInstagram } from "react-icons/io";
import { textAnimation } from "../../constants/animate";
import { useScroll } from "../Util/useScroll";

export const Footer: FC = () => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0);

  return (
    <motion.div
      className="flex flex-col items-center w-full pt-5 pb-10"
      animate={controls}
      ref={ref}
      initial={"hidden"}
      variants={textAnimation}
    >
      <h1 className={classNames("text-lg font-extralight")}>
        {t("HEADING.TITLE")}
      </h1>
      <p className={"text-base uppercase font-thin"}>
        {`-${t("HEADING.SUBTITLE")}-`}
      </p>
      <p className={" mt-8 mb-2 text-base uppercase font-extralight"}>
        {t("FOOTER.CONTACT")}
      </p>
      <a href={`mailto:${t("FOOTER.EMAIL")}`} className="flex">
        <AiOutlineMail className="mr-2" size={18} />
        <p className={"text-sm font-extralight mb-2"}>{t("FOOTER.EMAIL")}</p>
      </a>
      <a href={`tel:${t("FOOTER.PHONE").replaceAll(" ", "")}`} className="flex">
        <BsTelephone className="mr-2" size={18} />
        <p className={"text-sm font-extralight mb-2"}>{t("FOOTER.PHONE")}</p>
      </a>
      <a
        className="flex"
        href="https://www.instagram.com/palgol/"
        target={"_blank"}
      >
        <IoLogoInstagram className="mr-2" size={20} />
        <p className={"text-sm font-extralight mb-10"}>
          {t("FOOTER.INSTAGRAM")}
        </p>
      </a>
      <p className={"text-xs font-extralight"}>{t("FOOTER.RIGHTS")}</p>
      <p className={"text-xs font-extralight"}>{new Date().getFullYear()}</p>
    </motion.div>
  );
};
