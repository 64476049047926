export const textAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.5,
      ease: "easeIn",
    },
  },
};

export const fadeInAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.4,
      ease: "easeIn",
    },
  },
};

export const delayedFadeInAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 1,
      ease: "easeIn",
    },
  },
};
