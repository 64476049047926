import { Skeleton } from "@mui/material";
import classNames from "classnames";
import {
  DetailedHTMLProps,
  FC,
  ImgHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { useWindowResize } from "../Util/useWindowResize";

interface ImageProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

export const Image: FC<ImageProps> = ({ src }) => {
  const sizeInfo = useWindowResize();

  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoaded, setLoaded] = useState<boolean>();

  useEffect(() => {
    if (!src) {
      return;
    }

    if (sizeInfo.underXl) {
      setImageUrl(src?.replace("upload/", "upload/c_fill,h_600/"));
      return;
    }

    if (sizeInfo.xxl) {
      setImageUrl(src.replace("upload/", "upload/c_fill,h_2000/"));
      return;
    }

    setImageUrl(src.replace("upload/", "upload/c_fill,h_1000/"));
  }, [sizeInfo.underXl, sizeInfo.xxl, src]);

  const getImageWidth = () => {
    if (sizeInfo.underXl) {
      return document.body.offsetWidth - 64;
    }

    return document.body.offsetWidth / 2 - 64 - 8;
  };

  const getImageHeight = () => {
    return 0.666 * getImageWidth();
  };

  return (
    <div className={"mb-5 sm:mx-5"}>
      {!isLoaded && (
        <Skeleton
          variant="rectangular"
          height={getImageHeight()}
          width={getImageWidth()}
        />
      )}
      <img
        alt={src}
        src={imageUrl}
        onLoad={() => setLoaded(true)}
        className={classNames({ hidden: !isLoaded })}
        width={getImageWidth()}
      />
    </div>
  );
};
