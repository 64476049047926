import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { motion } from "framer-motion";

import { useScroll } from "../Util/useScroll";
import { textAnimation } from "../../constants/animate";

import classes from "./Heading.module.scss";

export const Heading: FC = () => {
  const { t } = useTranslation();
  const { ref, controls } = useScroll(0);

  return (
    <motion.div
      className="flex flex-col w-full items-center py-10"
      animate={controls}
      ref={ref}
      initial={"hidden"}
      variants={textAnimation}
    >
      <h1 className={classNames("text-2xl font-semibold", classes.Title)}>
        {t("HEADING.TITLE")}
      </h1>
      <p
        className={classNames(
          "text-base uppercase font-thin",
          classes.Subtitle
        )}
      >
        {t("HEADING.SUBTITLE")}
      </p>
    </motion.div>
  );
};
