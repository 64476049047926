import { FC, useState } from "react";
import { Footer } from "../components/Footer/Footer";
import { Gallery } from "../components/Gallery/Gallery";
import { Heading } from "../components/Heading/Heading";
import { Navigation } from "../components/Navigation/Navigation";
import { Category } from "../models/category.model";

export const HomePage: FC = () => {
  const [selected, setSelected] = useState<Category>(Category.SPORT);

  return (
    <div className="flex flex-col items-center">
      <Heading />
      <Navigation selected={selected} setSelected={setSelected} />
      <Gallery selectedCategory={Category[selected]} />
      <Footer />
    </div>
  );
};
