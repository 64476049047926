import { useEffect, useState } from "react";
import { throttle } from "lodash";

import { breakpoints } from "../../constants/constants";

export function useWindowResize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    underSm: window.innerWidth < breakpoints.sm,
    underMd: window.innerWidth < breakpoints.md,
    underLg: window.innerWidth < breakpoints.lg,
    underXl: window.innerWidth < breakpoints.xl,
    sm:
      window.innerWidth < breakpoints.md && window.innerWidth >= breakpoints.sm,
    md:
      window.innerWidth < breakpoints.lg && window.innerWidth >= breakpoints.md,
    lg:
      window.innerWidth < breakpoints.xl && window.innerWidth >= breakpoints.lg,
    xl:
      window.innerWidth < breakpoints.xxl &&
      window.innerWidth >= breakpoints.xl,
    xxl: window.innerWidth >= breakpoints.xxl,
  });

  useEffect(() => {
    const handleResize = throttle(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        underSm: window.innerWidth < breakpoints.sm,
        underMd: window.innerWidth < breakpoints.md,
        underLg: window.innerWidth < breakpoints.lg,
        underXl: window.innerWidth < breakpoints.xl,
        sm:
          window.innerWidth < breakpoints.md &&
          window.innerWidth >= breakpoints.sm,
        md:
          window.innerWidth < breakpoints.lg &&
          window.innerWidth >= breakpoints.md,
        lg:
          window.innerWidth < breakpoints.xl &&
          window.innerWidth >= breakpoints.lg,
        xl:
          window.innerWidth < breakpoints.xxl &&
          window.innerWidth >= breakpoints.xl,
        xxl: window.innerWidth >= breakpoints.xxl,
      });
    }, 200);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.addEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
