import { FC } from "react";
import { CircularProgress } from "@mui/material";

export const AppLoader: FC = () => (
  <CircularProgress
    size={48}
    sx={{
      color: "black",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "-24px",
      marginLeft: "-24px",
    }}
  />
);
