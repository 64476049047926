import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppLoader } from "./components/AppLoader/AppLoader";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="bg-white flex justify-center items-center h-screen w-screen" />
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>
);
